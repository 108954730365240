//
// Override global variables
//

// Theme colors
//$primary:       									#6993FF;
$primary:       									#000000;
$primary-hover:    									#4A7DFF;
$primary-light:    									#E1E9FF;
$primary-inverse:  									#FFFFFF;
